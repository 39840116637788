/**
 * General scripts for the substrates selector tool
 */

 window.addEventListener('load', function() {
	// Fan out swatches
	setTimeout(function() {
		$('#swatches').addClass('open');
	}, 200);

	// Open main dropdown
	$('#substrate-button, #technology-button, #industry-button, #priority-button').on( 'click', function() {
		$(this).toggleClass( 'active' );
	});

	// Open sub-items in dropdown
	$('.substrate-list__toggle').on( 'click', function() {
		$(this).toggleClass('active');
	});

	//If Printer Technology already has value on load, and another field also has a value, submit button.
	if ( $( '#technology' ).val() !== '' && ( $( '#substrate-selected' ).val() == '' && $('#industry-selected' ).val() == '' && $('#priority-selected' ).val() == '' )  ) {
		$('#submit').prop('disabled', true);
	}

	// Set Technology dropdown button and hidden form field value
	$( '#technology-list a' ).on( 'click', function() {
		$( '#technology-button' ).text( $(this).text() ).removeClass( 'active' );
		$( '#technology' ).val( $(this).data('val') );
		$(this).closest('.substrate-list').siblings('.clear-field').show();

		// Disable submit if only tech is selected
		if ( $( '#industry-selected' ).val( ) === '' && $( '#substrate-selected' ).val() === '' && $('#priority-selected' ).val() == '' ) {
			document.getElementById("submit").disabled = true;
			$("#search-error").addClass('active');
		}
	});

	// Set Substrate dropdown button and hidden form field value
	$( '#substrate-list a' ).on( 'click', function() {
		var val = $(this).text();
		var valId = $(this).parent().attr( 'id' );
		$( '#substrate-button' ).text( val ).removeClass( 'active' );
		$( '#substrate-selected' ).val( valId );
		$(this).closest('.substrate-list').siblings('.clear-field').show();
		document.getElementById("submit").disabled = false;
		$("#search-error").removeClass('active');
	});

	// Set Industry dropdown button and hidden form field value
	$( '#industry-list a' ).on( 'click', function() {
		var val = $(this).text();
		var valId = $(this).parent().attr( 'id' );
		$( '#industry-button' ).text( val ).removeClass( 'active' );
		$( '#industry-selected' ).val( valId );
		$(this).closest('.substrate-list').siblings('.clear-field').show();
		document.getElementById("submit").disabled = false;
		$("#search-error").removeClass('active');
	});

	// Set priority dropdown button and hidden form field value
	$( '#priority-list a' ).on( 'click', function() {
		var val = $(this).text();
		var valId = $(this).parent().attr( 'id' );
		$( '#priority-button' ).text( val ).removeClass( 'active' );
		$( '#priority-selected' ).val( valId );
		$(this).closest('.substrate-list').siblings('.clear-field').show();
		document.getElementById("submit").disabled = false;
		$("#search-error").removeClass('active');
	});

	// Close dropdown on ESC
	$(document).keyup(function(e) {
		if (e.keyCode == 27) { // escape key maps to keycode `27`
			if ( $( '#substrate-button, #technology-button, #industry-button, #priority-button' ).hasClass( 'active' ) ) {
				$( '#substrate-button, #technology-button, #industry-button, #priority-button' ).removeClass( 'active' );
			}
		}
	});

	// Close dropdown on click-away
	$(document).click(function(){
		$( '#substrate-button, #technology-button, #industry-button, #priority-button' ).removeClass( 'active' );
	});
	  
	// Clicks within the dropdown/toggle button won't bubble up and close it.
	$( '#substrate-list, #substrate-button, #technology-button, #technology-list, #industry-list, #industry-button, #priority-list, #priority-button' ).click(function(e){
		e.stopPropagation();
	});

	// Close other dropdowns when opening another
	$( '#substrate-button, #technology-button, #industry-button, #priority-button' ).click(function(e) {
		$(this).parent().siblings('.search__field').find('.substrate-button').removeClass('active');
	});

	// Clear fields button
	$( '.clear-field' ).on('click', function() {
		var label = $(this).siblings('.substrate-button').data('default-label');
		$(this).siblings('.substrate-button').text(label);
		$(this).siblings('.hidden-field').attr( 'value', '');
		$(this).hide();
	});


	/**
	 * Results Page
	 */
	// Open Ink Type collapsed content
	$('.results__item-top').on( 'click', function() {
		$(this).toggleClass('active');
		$(this).siblings('.results__item-bottom').slideToggle(250);
	});


	/**
	 * Shows ink types based on technology, substrate, and priority selection and hides all others.
	 * If no value is given it will show all ink types.
	 * 
	 * @param technology string
	 * @param substrate string
	 * @param priority string
	 * @param industry string
	 */
	function showInk(technology, substrate, priority, industry) {
		// Concatenate classes
		var classes = '';

		if (technology) { classes += '.' + technology + '';}
		if (substrate) { classes += '.' + substrate + '';}
		if (priority) { classes += '.' + priority + '';}
		if (industry) { classes += '.' + industry + '';}

		$('.results__item').each(function() {
			if ( $(this).is( classes ) ) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});

		hasResults();
	}


	/**
	 * Tests if there are any visible ink type rows to determine if "no results"
	 * message should be shown.
	 */
	function hasResults() {
		if ( !$('.results__item').is(':visible') ) {
			$('#no-results').show();
		} else {
			$('#no-results').hide();
		}
	}

	// Will run on load, and also after filtering events later
	hasResults();

	/**
	 * When filter dropdowns change, get their value and pass to filtering function
	 */
	$('#tech-filter, #page_id, #priority-filter').on( 'change', function() {
		var technology = $('#tech-filter').val();
		if ( technology == ('' || null) ) {technology = false;}
		var substrate = $('#page_id').val();
		if ( substrate == ('' || null) ) {substrate = false;}
		var priority = $('#priority-filter').val();
		if ( priority == ('' || null) ) {priority = false;}
		var industry = $('#industry-selected').val();
		if ( industry == ('' || null) ) {priority = false;}

		showInk(technology, substrate, priority, industry);
	});

	/**
	 * If filters have values on load, do the filtering
	 */
	var technology = $('#tech-filter').val();
	var substrate = $('#page_id').val();
	var priority = $('#priority-selected').val();
	var industry = $('#industry-selected').val();

	if ( technology || substrate || priority || industry ) {
		showInk(technology, substrate, priority, industry);
	}

	/**
	 * ============================
	 * ==== Show/hide elements ====
	 * ============================
	 */

	/**
	 * Social Share Animation Toggle
	 */
	$('#share-toggle').on('click', function() {
		$('#social-share').toggleClass('active');
		$('.selector__footer--share').toggleClass('active');
		$('.selector__footer').toggleClass('active');
	});
});

// Custom validation message for fake select fields
var elements = document.querySelectorAll( '.hidden-field' );
for (var i = 0; i < elements.length; i++) {
	elements[i].oninvalid = function(e) {
		e.target.setCustomValidity("");
		if (!e.target.validity.valid) {
			e.target.setCustomValidity( "Please select an item in the list." );
		}
	};
	elements[i].oninput = function(e) {
		e.target.setCustomValidity("");
	};
}
